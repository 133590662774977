import { ElMessage } from "element-plus";
import { useUserInfo } from "@/utils/useUserInfo";
import { httpPost } from "@/api/httpService";
import VueCookies from "vue-cookies";
/**
 * 判断用户是否登录
 */
export const checkUserLoginStatus = function () {
  return VueCookies.isKey("tokenWeb") && VueCookies.isKey("userInfo");
};
/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
const Debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};
/**错误提示 */
export const remindErrorMessage = (message) => {
  if (!window.sessionStorage.getItem("cj_canRemindErrorMessage")) {
    window.sessionStorage.setItem("cj_canRemindErrorMessage", 1);
    ElMessage({
      message: message,
      type: "warning",
    });
    setTimeout(() => {
      window.sessionStorage.removeItem("cj_canRemindErrorMessage");
    }, 2000);
  }
};

export function formatNumber(number) {
  if (!number) {
    return 0;
  }
  if (number < 1000) {
    return number.toString(); // 如果数字小于1000，直接返回原始数字
  } else if (number < 10000) {
    // 如果数字小于1万，将数字除以1000并保留一位小数，然后加上'k'单位
    return (number / 1000).toFixed(1) + "k";
  } else {
    // 如果数字大于等于1万，将数字除以10000并保留一位小数，然后加上'w'单位
    return (number / 10000).toFixed(1) + "w";
  }
}
export function objectToUrlParams(obj = {}) {
  var params = [];
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key]) {
        params.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
        );
      }
    }
  }
  return params.join("&");
}
/**
 * 用于首次进入该产品，用户信息同步
 */
export const synchronizeUserPermission = async () => {
  const { userId } = useUserInfo();
  let ifLogin = window.sessionStorage.getItem("ifLoginSPIDER_" + userId); //标志位
  if (ifLogin === "true" || !userId) {
    return;
  }
  let res = await httpPost("/user/v1/loginOrRegister", null);
  if (res?.code == 0) {
    window.sessionStorage.setItem("ifLoginSPIDER_" + userId, true);
  }
};
export { Debounce };
